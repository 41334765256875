<template>
    <div id="main">
        <sidebar :page="'Learn'" :sidebar_data=sidebar_data></sidebar>

        <nav class="navbar bg-transparent p-0 pb-2">
            <div class="container-fluid">
                <a class="navbar-brand bars d-flex me-2 mt-2" @click="this.$emit('update:screen', this.screen - 1)">
                <em class="ph-arrow-left text-secondary me-2"></em>
                </a>

                <img :src="require('../assets/img/logo-orange.png')" alt="" style="height: 75px;">

                <notifications :user="user" :color_notifications_icons="'orange'" />

                <div class="divider mt-4 d-none"></div>
            </div>
        </nav>

        <div id="searchContainer" ref="searchContainer">
            <input type="text" id="searchInputField" @input="searchLearnDataFilter"
                placeholder="Search for test/treatment/condition.">
            <button id="searchBtn" class="btn">
                <font-awesome-icon :icon="['fa', 'search']" />
            </button>
            <router-link :to="{ name: 'LearnBookmarks'}">
                <span>
                    <i id="bookmarkIcon" class="mdi mdi-bookmark"></i>
                </span>
            </router-link>
            <!-- 
            Dropdown container -->
            <div v-if="showDropdown" id="searchResultsDropdown">
                <ul>
                    <li v-for="item in searchResults" :key="item.id" @click="selectItem(item.id)">
                    {{ item.title }}
                    </li>
                </ul>
            </div>
        </div>

        <div id="topIcons">
            <router-link to="/learn" class="topIcons">
                <i class="mdi mdi-book-open-page-variant"></i>
            </router-link>
            <i class="mdi mdi-bookmark topIcons" @click="toggleBookmarkState" :class="{ 'mdi mdi-bookmark': hasBookmark, 'mdi mdi-bookmark-outline': !hasBookmark }"></i>
            <i class="mdi mdi-share-variant topIcons" @click="toggleMenu"></i>
            <i class="mdi mdi-download topIcons" @click="downloadAsPDF"></i>
    
            <!-- Menu with icons -->
            <div v-if="isMenuOpen" class="floatingMenu">
                <i class="mdi mdi-whatsapp" @click="shareOnWhatsApp"></i>
                <i class="mdi mdi-facebook" @click="shareOnFacebook"></i>
                <i class="mdi mdi-twitter" @click="shareOnTwitter"></i>
                <i class="mdi mdi-email" @click="shareOnEmail"></i>
            </div>
        </div>

        <div ref="pageContent">
            <div v-if="learnData">
            <div ref="titleContainer">
                <div id="title">{{ learnData.title }}</div>
            </div>
            <p ref="descriptionContainer" id="description" v-html="learnData.description"></p>
            <div id="tagContainer" ref="tagContainer">
                <span v-for="(tag, index) in learnData.tags.split(',')" :key="index">{{ tag }}</span>
            </div>
            </div>
        </div>

        <div v-if="relatedTitles">
            <div id="relatedLinksTitle">Related Links</div>
            <div v-if="relatedTitles.length > 0" class="relatedLinksContainer">
                <router-link v-for="(title, index) in relatedTitles" :key="index" :to="{ name: 'LearnDetails', params: { id:  title.id }}">
                    <span class="relatedLinks" >{{ title.title }}</span>
                </router-link>
            </div>
        </div>
        
        <floating-nav :page="'Learn'"></floating-nav>
    </div>
</template>
<script>
  import Notifications from '../components/Notifications'
  import FloatingNav from '../components/FloatingNav'
  import Sidebar from '../components/AutoSidebar'
  //
  //Material design icon library.
    import '@mdi/font/css/materialdesignicons.min.css';
    //
    //To help download HTML content as pdf.
    import html2pdf from 'html2pdf.js';
  //
  // Import Axios for making HTTP requests.
  import axios from 'axios';
  //
  export default {
    name: 'LearnDetails',
    props: {
        id: {
            type: String,
            required: true
        }
    },
    components: {
      Notifications,
      FloatingNav,
      Sidebar
    },
    data() {
        return {
            sidebar_data: [
                { title: 'Home', link: '', if_children: false, icon: 'home', modal: false },
                { title: 'My Account', link: 'profile', if_children: false, icon: 'user', modal: false },
            ],
            learnData: null,
            relatedTitles: null,
            //
            // Initialize hasBookmark state
            hasBookmark: false,
            //
            isMenuOpen: false,
            encodedMessage: `Check out what I've learnt about ${this.learnData ? this.learnData.title : 'title'} from the Gsort app. You can download the app using this link to learn more about health and wellness 
                https://play.google.com/store/apps/details?id=com.gearhealthsystem.app.twa&pcampaignid=web_share\n\nAlso. Use this if you’re using a browser https://gearhealthsystem.com/learn`,
            //
            // Search query string
            searchQuery: '',
            searchResults: [],
            //
            //Hide or show the dropdown.
            showDropdown: false
        }
    },
    watch: {
        '$route.params.id'(newId) {
        this.getLearnDetails(newId);
        }
    },
    methods: {
        //
        //Retrieve learn details from the database.
        async getLearnDetails(id) {
            try {
            const response = await axios.get('api/learn/getLearnDetails', {
                params: { id }
            });
            this.learnData = response.data.learnDetails;
            this.relatedTitles = response.data.relatedTitles;
            this.hasBookmark = response.data.hasBookmark;
            } catch (error) {
            console.error('Error fetching learn data:', error);
            }
        },
        //
        //Download learn details as pdf.
        async downloadAsPDF() {
            if (!this.learnData || !this.$refs.pageContent) {
                console.error('Data or elements are not available');
                return;
            }

            this.$nextTick(() => {
                const element = this.$refs.pageContent;

                html2pdf().set({
                    pagebreak: { mode: 'avoid-all' },
                    margin: 1,
                    filename: 'downloaded-content.pdf',
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 1 },
                    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
                }).from(element).save();
            });
        },
        //
        //Share learn details on social networks.
        // Toggle visibility of social media icons
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        //
        // Share on WhatsApp.
        shareOnWhatsApp() {
            const title = this.learnData ? this.learnData.title : 'title';
            const message = `Check out what I've learnt about ${title} from the Gsort app. You can download the app using this link to learn more about health and wellness: https://play.google.com/store/apps/details?id=com.gearhealthsystem.app.twa&pcampaignid=web_share\n\nAlso, use this if you’re using a browser: https://gearhealthsystem.com/learn`;
            const encodedMessage = encodeURIComponent(message);
            const imageUrl = '../assets/img/icons/1.png';
            const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}%0A${imageUrl}`;
            window.open(whatsappUrl, '_blank');
        },
        //
        // Share on Facebook
        shareOnFacebook() {
            const title = this.learnData ? this.learnData.title : 'title';
            const message = `Check out what I've learnt about ${title} from the Gsort app. You can download the app using this link to learn more about health and wellness. Also, use this if you’re using a browser.`;
            const encodedMessage = encodeURIComponent(message);
            const facebookUrl = `https://www.facebook.com/sharer/sharer.php?text=${encodedMessage}`;
            window.open(facebookUrl, '_blank');
        },
        // Share on Twitter
        shareOnTwitter() {
            const title = this.learnData ? this.learnData.title : 'title';
            const message = `Check out what I've learnt about ${title} from the Gsort app. You can download the app using this link to learn more about health and wellness 
                https://play.google.com/store/apps/details?id=com.gearhealthsystem.app.twa&pcampaignid=web_share\n\nAlso. Use this if you’re using a browser https://gearhealthsystem.com/learn`;
            const encodedMessage = encodeURIComponent(message);
            const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}`;
            window.open(twitterUrl, '_blank');
        },
        //
        // Share on Instagram
        shareOnEmail() {
            const title = this.learnData ? this.learnData.title : 'title';
            const message = `Check out what I've learnt about ${title} from the Gsort app. You can download the app using this link to learn more about health and wellness 
            https://play.google.com/store/apps/details?id=com.gearhealthsystem.app.twa&pcampaignid=web_share%0D%0ANo app? Use this if you’re using a browser https://gearhealthsystem.com/learn`;
            const encodedMessage = encodeURIComponent(message);
            const emailUrl = `mailto:?subject=Check out what I have learnt&body=${encodedMessage}`;
            window.location.href = emailUrl;
        },
        //
        // Method to fetch bookmark state
        async fetchBookmarkState() {
            try {
                const response = await axios.get('api/learn/getBookmarkState', {
                params: {
                    id: this.id // Assuming you have access to the ID of the current learn item
                }
                });
                // Update hasBookmark state based on the response
                this.hasBookmark = response.data.hasBookmark;
            } catch (error) {
                console.error('Error fetching bookmark state:', error);
            }
        },
        //
        //Save the learn details for easier access by user.
        async toggleBookmarkState() {
            //
            try {
                //
                // Fetch data based on id.
                const response = await axios.get('api/learn/toggleBookmarkState', {
                    params: {
                        id: this.id
                    }
                });
                //
                // Update bookmark state based on response.
                this.hasBookmark = response.data.hasBookmark;
            } catch (error) {
                //
                console.error('Error fetching learn data:', error);
            }
        },
        //
        //Get learn data according to what the user has typed.
        async searchLearnDataFilter(event) {
            //
            //Get the search words from input field.
            this.searchQuery = event.target.value;
            //
            //Check if there is a search word in the input field, to determine if to show dropdown menu or not.
            if (!this.searchQuery) {
                //
                //If no word, hide, dropdown menu.
                this.showDropdown = false;
                return;
            }
            //
            try {
                this.loading = true;
                //
                //Get search results from database.
                const response = await axios.get('api/learn/searchLearnDataFilter', {
                    params: {
                        query: this.searchQuery
                    }
                });
                //
                //Save search results.
                this.searchResults = response.data.data;
                //
                //Show dropdown menu with the result titles.
                this.showDropdown = true;
            } catch (error) {
                console.error('Error fetching learn data:', error);
            } finally {
                this.loading = false;
            }
        },
        //
        //Help the user select dropdown menu and open the learn details they prefer.
        selectItem(id) {
            //
            // Navigate to LearnDetails component with the selected item's id
            this.$router.push({ name: 'LearnDetails', params: { id: id } });
            //
            // Hide the dropdown after selecting an item
            this.showDropdown = false;
        },
        handleClickOutside(event) {
            //
            const container = this.$refs.searchContainer;
            //
            // Check if the clicked element is not inside the search container
            if (container && !container.contains(event.target)) {
                //
                // Clicked outside the dropdown, hide it
                this.showDropdown = false;
            }
        }
    },
    computed: {
      user() {
        return this.$store.state.user
      }
    },
    async created() {
        //
        this.getLearnDetails(this.id); // Fetch initial learn details based on ID
        //
        // Watch for changes in route parameter 'id'
        this.$watch('$route.params.id', (newId) => {
            //
            // Fetch new learn details when ID changes.
            //This happens when the user clie
            this.getLearnDetails(newId);
        });
        //
        // Add a global click event listener to handle clicks outside the dropdown
        document.addEventListener('click', this.handleClickOutside);
    }
  }
</script>

<style scoped>
    #main {
        position: relative;
        margin: 0 0 50% 0;
    }

    #main::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/img/logo-orange.png');
        background-size: 100%; /* Adjust as needed */
        background-repeat: repeat;
        opacity: 0.1; /* Adjust the opacity of the background image */
        z-index: -1;
    }

    #main > * {
        /* position: relative; */
        z-index: 1;
    }

    #searchContainer {
    display: flex;
    justify-content: center;
    height: 6vh;
    position: relative; /* Ensure parent container has relative positioning */
    }

    #searchInputField {
    width: 70%;
    /* height: 120%; */
    border-radius: 1.2rem 0 0 1.2rem;
    }

    #searchResultsDropdown {
    position: absolute;
    top: 100%; /* Position dropdown below input field */
    left: 9%;
    width: 65%;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2;
    }

    #searchResultsDropdown ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    }

    #searchResultsDropdown li {
    padding: 8px 16px;
    cursor: pointer;
    }

    #searchResultsDropdown li:hover {
    background-color: #f0f0f0;
    }

    #searchBtn {
    background: #1e3148;
    color: white;
    border-radius: 0 1.2rem 1.2rem 0;
    }
    #bookmarkIcon{
        font-size: 2rem;
        color: #1e3148;
        margin: 2% 0 0 50%;
    }
    #topIcons{
        display: flex;
        justify-content: center;
        margin: 5% 0 5% 0;
    }
    #shareNodesIcon{
        width: 1.5rem;
        height: 1.5rem;
        margin-top: -0.6%;
    }
    .topIcons{
        color: #1e3148;
        font-size: 1.5rem;
        margin: 0 5% 0 0;
    }
    #title{
        font-size: 1.5rem;
        font-weight: 700;
        margin: 3% 0 2% 4%;
    }
    #description {
        /* 
        Other styles for #description */
        font-size: 1.2rem;
        margin: 3% 2% 3% 2%;
    }
    #description >>> .descriptionHeader {
        display: block;
        font-weight: bold;
        margin: 2% 0 1% 2%;
    }
    .tagsContainer {
        font-style: italic;
        margin: 2% 0 1.5% 4%;
    }

    #tags {
        font-style: italic;
        font-size: small;
        background: #EDEADE;
        margin: 2% 0.5% 0 0.5%;
        padding: 0.6% 2% 0.6% 2%;
        border-radius: 2rem;
    }
    #tags:hover{
        cursor: pointer;
    }
    #relatedLinksTitle{
        font-weight: 600;
        margin: 2% 0 2% 4%;
    }
    .relatedLinks{
        margin-left: 4%;
        margin-bottom: 50%;
    }
    /*
    Option to share on social media. */
    /* Social media icons styling */
    /* Floating menu styles */
    .floatingMenu {
        position: absolute;
        width: 32%;
        top: 200px; /* Adjust top position as needed */
        right: 39%; /* Adjust right position as needed */
        display: flex;
        flex-direction: row;
        background-color: white;
        border: 1px solid #ccc;
        /* padding: 10px; */
        border-radius: 5px;
        z-index: 1; /* Ensure menu appears above other content */
    }

    .floatingMenu i {
        cursor: pointer;
        font-size: 24px;
        margin: 0 4% 2% 6%;
        color: #1e3148;
    }

    .floatingMenu .mdi-twitter{
        color: #1DA1F2;
    }
    .floatingMenu .mdi-facebook{
        color: #316FF6;
    }
</style>